import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Cookies from 'js-cookie';
import axios from "axios";
import { useCookies } from "react-cookie";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  const [userData, setUserData] = useState(null);
  const [scanData, setScanData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [instantPlans, setInstantPlans] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null);


  const [loading, setLoading] = useState(true);

  const [userLoaded, setUserLoaded] = useState(false);
  const [scansLoaded, setScansLoaded] = useState(false);
  const [plansLoaded, setPlansLoaded] = useState(false);
  const [instantPlansLoaded, setInstantPlansLoaded] = useState(false);
  const [cookiesLoaded, setCookiesLoaded] = useState(false);
  const [teamLoaded, setTeamLoaded] = useState(false);



  const axiosInstance = axios.create({
    baseURL: "https://api.cybersanctus.com/api",
    // baseURL: "http://localhost:2050/api",
    // timeout: 120000,
    // signal: AbortSignal.timeout(120000)
  });

  const connectGitHub = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {

      try{
          const url = '/v1/users/githubToken';
          const { data } = await axiosInstance.post(
            url,
            {
              "code": code,
            },
            {
              headers: { authorization: `Bearer ${cookies.jwt}` }
            }
        );
      } catch(error){
        console.log(error);
      }
    }
  };


  const fetchUser = async (e) => {
    setUserLoaded(false);
    try {
        const url = "/v1/users/me";
        const user = await axiosInstance.get(url, {
            headers: { authorization: `Bearer ${cookies.jwt}` },
        });
        setUserData(user);
        setUserLoaded(true);
        // setLoading(false);
    } catch (error) {
        //hidden console.log(error);
        // setLoading(false);
        setUserLoaded(false);
    }
  };

  const fetchScans = async () => {
    setScansLoaded(false);
    // setLoading(true);
    try{
      const url = '/v1/users/myScans';
      const res = await axiosInstance.get(url, {
        headers: { authorization: `Bearer ${cookies.jwt}` },
      });
      setScanData(res.data.scans);
      // setLoading(false);
      setScansLoaded(true);
    } catch(err) {
      setScansLoaded(false);
      // setLoading(false);
    }
  }

  const fetchPlans = async () => {
    setPlansLoaded(false);
    try{
        const url = '/v1/plans';
        const res = await axiosInstance.get(url);
        setPlanData(res.data);
        setPlansLoaded(true);
    }catch(err) {
        // removed log(err);
        setPlansLoaded(false);
    }
  }

  const fetchInstantPlans = async () => {
    setInstantPlansLoaded(false);
    try{
        const url = '/v1/plans/instant';
        const res = await axiosInstance.get(url);
        setInstantPlans(res.data);
        setInstantPlansLoaded(true);
    }catch(err) {
        // removed log(err);
        setInstantPlansLoaded(false);
    }
  }

  const getTeam = async (e) => {
    setTeamLoaded(false);
    try {
        const url = "/v1/teams/members";
        const team = await axiosInstance.get(url, {
            headers: { authorization: `Bearer ${cookies.jwt}` },
        });
        setTeamMembers(team.data.team);
        setTeamLoaded(true);
    } catch (error) {
        setTeamMembers(null);
        setTeamLoaded(true);
        console.log(error)
    }
    setTeamLoaded(true);
  };

  useEffect(() => {
    connectGitHub();
    if(cookies.jwt){
      setCookiesLoaded(true)
    }
    console.log(teamMembers);
  }, [])

  useEffect(() => {
    if(cookies.jwt){
      setCookiesLoaded(true)
    }
  }, [cookies])

  useEffect(() => {
    // setLoading(true);
    if(cookiesLoaded){
      fetchUser();
      fetchScans();
      fetchPlans();
      fetchInstantPlans();
      getTeam();
    }
    // setLoading(false);
  }, [cookiesLoaded])

  useEffect(() => {
    if(userLoaded && scansLoaded && plansLoaded && instantPlansLoaded && cookiesLoaded){
      setLoading(false);
    }
  }, [userLoaded, scansLoaded])

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes, userDataParam) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const Component = prop.component;
        if(prop.name === "Team" && userDataParam.data.user.purchasedPackage.package != "668d1c138af437da8e5ebe5b") {
          return;
        } else{
          return (
            <Route path={`/${prop.path}`} element={<Component userData={userDataParam} scanData={scanData} planData={planData} instantPlans={instantPlans} teamMembers={teamMembers} axiosInstance={axiosInstance} />} key={key} />
          );
        }
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("dark");

    window.addEventListener('message', function(message) {
      if (message.origin === 'https://cybersanctus.com' || message.origin === 'https://www.cybersanctus.com') {
        var data = JSON.parse(message.data);
        var jwt = data.jwt;
        if (jwt){
          localStorage.setItem("jwt", jwt);
          setCookie("jwt", jwt);
          setCookiesLoaded(true);
          // window.location.reload();
        }
      }
    });
  })

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <ToastContainer limit={1}/>
      <iframe id="iframe" src="https://cybersanctus.com/external.html" style={{width:0,height:0,border:'none',position:'absolute'}}></iframe>
      <Sidebar open={open} isBusiness={(userData ? userData.data.user.purchasedPackage.package != "668d1c138af437da8e5ebe5b" : false)} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              userData={userData}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              {/* <Routes>
                {getRoutes(routes, userData)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes> */}
              {loading ? (
                <div>Loading...</div> // You can replace this with a spinner or any loading component
              ) : (
                <Routes>
                  {getRoutes(routes, userData)}
                  <Route path="/" element={<Navigate to="/admin/default" replace />} />
                </Routes>
              )}
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
